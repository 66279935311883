// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-benchmarks-index-tsx": () => import("./../../../src/pages/benchmarks/index.tsx" /* webpackChunkName: "component---src-pages-benchmarks-index-tsx" */),
  "component---src-pages-benchmarks-linkedlist-tsx": () => import("./../../../src/pages/benchmarks/linkedlist.tsx" /* webpackChunkName: "component---src-pages-benchmarks-linkedlist-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-rust-storing-data-in-multiple-containers-tsx": () => import("./../../../src/pages/blog/rust-storing-data-in-multiple-containers.tsx" /* webpackChunkName: "component---src-pages-blog-rust-storing-data-in-multiple-containers-tsx" */),
  "component---src-pages-data-structures-index-tsx": () => import("./../../../src/pages/data-structures/index.tsx" /* webpackChunkName: "component---src-pages-data-structures-index-tsx" */),
  "component---src-pages-data-structures-linkedlist-tsx": () => import("./../../../src/pages/data-structures/linkedlist.tsx" /* webpackChunkName: "component---src-pages-data-structures-linkedlist-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

